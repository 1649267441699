.itemsContainer {
  @apply border border-solid border-[#00F0FF] absolute -top-[10.5rem] -left-2 py-3 px-3.5 w-44 bg-[#021B23] rounded-md z-[101] h-40 overflow-y-auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #707070;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(184, 184, 184);
  }
}