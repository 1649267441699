.modal {
  @apply bg-gradient-to-b from-[#141414] to-[#011C24] flex flex-col lg:flex-row md:w-[90vw] lg:w-[80vw] lg:h-[80dvh] xl:w-[1044px] p-5 lg:p-[30px] rounded-2xl;

  .statisticSection {
    @apply w-full mb-4 lg:m-0 lg:w-[calc(100%-500px)] xl:w-[calc(100%-635px)] lg:flex lg:flex-col lg:justify-between;
  }

  .contentSection {
    @apply w-full lg:w-[500px] xl:w-[635px] bg-[#ADADAD] rounded-[15px] bg-opacity-15 lg:ml-4 text-white p-4 lg:p-9 lg:overflow-auto scrollbar-hide mb-16 lg:m-0;
  }
}

@mixin afterBg {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}
// module.scss
.hoverAnimation {
  display: inline-block;
  position: relative;
  color: #fff;
  text-decoration: none;
}

.hoverAnimation::before {
  content: '';
  position: absolute;
  width: 0; /* Initially, the underline is hidden */
  height: 1px;
  bottom: 0;
  left: 50%;
  background-color: #fff;
  transition: width 0.4s ease, left 0.4s ease;
}

.hoverAnimation:hover::before {
  width: 100%; /* Expand the underline on hover */
  left: 0; /* Start the underline from the left */
}

.pulse {
  animation: shockwaveJump 1s ease-out infinite;

  &:after {
    @include afterBg;
    animation: shockwave 1s 0.65s ease-out infinite;
  }

  &:before {
    @include afterBg;
    animation: shockwave 1s 0.5s ease-out infinite;
  }
}
@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
  }
  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(2.25);
  }
}
