.wrapper {
  @apply flex flex-col relative rounded-[15px] min-h-[200px] h-full
  cursor-pointer max-w-[350px] xl:max-w-[380px] w-full hover:scale-[1.03] transition p-[3px];
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.3);

  .cardHeader {
    @apply relative flex w-full h-auto justify-between items-start gap-2 -mt-[28px];
    .sideLine {
      @apply absolute h-auto -my-[3px] min-h-[60px] w-[4px] bg-[#AF3DFF] rounded-lg;
    }
    .headText {
      @apply text-[#9597A1];
    }
  }

  .cardMiddle {
    @apply mt-auto;
    ::-webkit-scrollbar {
      @apply w-[5px] h-[5px];
    }
    ::-webkit-scrollbar-track {
      @apply bg-[#707070] rounded-[16px];
    }
    ::-webkit-scrollbar-thumb {
      @apply bg-[white] rounded-[16px];
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgb(184, 184, 184);
    }
    .eachPrize {
      @apply flex justify-between items-center gap-2 py-2 border-b-[1px] last-of-type:border-none border-b-[#363843];

      .prizeText {
        @apply text-[14px] xl:text-[16px]  md:text-[18px] font-[500];
      }
    }
  }
}
.cardFooter {
  @apply relative flex flex-col gap-[12px] mt-2 ;
  .participated {
    @apply mt-[6px] mb-[8px] font-[400] text-[14px] xl:text-[16px];

    .participatedCount {
      @apply font-bold;
    }
  }

  .countDown {
    @apply relative flex justify-between items-center h-[37px] rounded-[8px] text-[#47FEFF] text-[14px] xl:text-[16px] px-[20px] py-[8px] border border-[#47FEFF];
    .intro {
      @apply relative;
    }
    .counter {
      @apply relative font-bold;
    }
  }

  .cta {
    @apply relative h-[33px] xl:h-[38px] 2xl:h-[44px] rounded-[8px] text-[16px] xl:text-[18px] 2xl:text-[20px] font-[700];
  }
}
